<template>
	<div :class="['toast-list', alignment, placement]">
		<ToastNotification
			v-for="toast in toasts"
			:toast="toast"
			:key="toast.id"
			@remove="$emit('remove', $event)"
		/>
	</div>
</template>

<script setup lang="ts">
import type { ToastPosition, Toast } from "~/components/platform/globals/Toast/Toast";

const props = defineProps({
	toasts: {
		type: Array as PropType<Toast[]>,
		default: () => [],
	},
	position: {
		type: String as PropType<ToastPosition>,
		default: "bottom-center",
		validator: (value: string) =>
			["top-right", "top-left", "bottom-right", "bottom-left", "top-center", "bottom-center"].includes(value),
	},
});

defineEmits(["remove"]);

const alignment = computed(() => {
	return props.position.split("-").at(0);
});

const placement = computed(() => {
	switch (props.position.split("-").at(1)) {
		case "right":
			return "right";
		case "left":
			return "left";
		case "center":
			return "center";
		default:
			return "center";
	}
});
</script>

<style scoped lang="scss">
.toast-list {
	position: fixed;
	z-index: 9999;
	display: flex;
	flex-direction: column;
	gap: $spacing-s;
	width: 100%;
}

.top {
	top: $spacing-m;
}

.bottom {
	bottom: $spacing-m;
}

.center {
	left: 50%;
	transform: translateX(-50%);
	align-items: center;
}

.right {
	right: $spacing-s;
	align-items: flex-end;
}

.left {
	bottom: $spacing-m;
	left: $spacing-s;
	align-items: flex-start;
}
</style>
