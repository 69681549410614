<template>
	<TnNotificationInline
		class="toast"
		orientation="horizontal"
		:type="toast.type"
		:title="toast.title"
		:closable="toast.closable"
		@close="$emit('remove', toast.id)"
	>
		{{ toast?.message }}

		<template #actions>
			<Component
				v-for="(component, index) in resolvedComponent"
				:key="index"
				:is="component.component"
				v-bind="component.props"
				@click="component.action"
			/>
		</template>
	</TnNotificationInline>
</template>

<script setup lang="ts">
import type { Toast, ToastActionButton } from "~/components/platform/globals/Toast/Toast";

const props = defineProps({
	toast: {
		type: Object as PropType<Toast>,
		required: true,
	},
});

const emit = defineEmits(["remove"]);

const resolvedComponent = computed(() => {
	return (props.toast?.actionButtons ?? []).map((button: ToastActionButton) => {
		return {
			component: resolveComponent("TnButton"),
			props: button.props,
			action: button.action,
		};
	});
});

onMounted(() => {
	if (props.toast.duration === 0) return;
	setInterval(() => {
		emit("remove", props.toast.id);
	}, props.toast.duration);
});
</script>

<style scoped lang="scss">
.toast {
	max-width: 500px;
}
</style>
