<template>
	<div>
		<Toasts
			:toasts="toasts"
			:position="position"
			@remove="remove"
		/>
	</div>
</template>

<script setup lang="ts">
const { toasts, remove, position, emptyToasts, emptyToastOnRouteChange } = useToast();
const route = useRoute();

watch(
	route,
	() => {
		if (emptyToastOnRouteChange.value) emptyToasts();
	},
	{
		immediate: true,
	},
);
</script>

<style scoped lang="scss"></style>
